<template>
  <div>
    <div :class="['headerPc', {clearfix: clearfix}, {ieStyle: ieStyle}]">
      <div class="logo-content " @mousedown="toHome()">
        <img class="logo" :src="imgUrl"/>
      </div>
      <div class="btns">
          <div class="btnItem" v-for="(item, index) in gData.btns" :key="index" @click="jump(item.url)" v-bind:class="{'chooseBtnItem': gData.index == index }">
            <div class="mouseHover" v-if="item.url">{{item.name}}</div>
            <div v-if="!item.url" @mouseenter="isRecruitShow = true" @mouseleave="isRecruitShow = false">
              {{item.name}}
              <div v-if="item.children && isRecruitShow" class="btnItemChildren">
                <div v-for="(itemChildren, index) in item.children" :key="index">
                  <span class="mouseHover" @click="jumpRecruit(itemChildren.url)">{{itemChildren.name}}</span>
                </div>
              </div>
            </div>
            <div class="line" v-if="item.url && gData.index == index"></div>
          </div>
      </div>
      <div class="devlopLogin" @click="jumpDeveloperLogin()">开发者登录</div>
    </div>
    <div class="entity"></div>
  </div>
</template>

<script>
import Config from 'utils/Config';
import iEUtils from 'utils/ieUtils';
export default {
  name: 'headerPc',
  props: {
    gData: Object
  },
  created() {
    if (iEUtils.isIE()/*  && iEUtils.getIEVersion() === 10 */) {
      // this.clearfix = 1;
      this.ieStyle = 1;
    }
  },
  data(){
      return {
        // 解决ie不对齐问题
        clearfix: 0,
        ieStyle: 0,
        isRecruitShow: false,
        imgUrl:require("../assets/logo_xmiles_top.svg")
      }
  },
  methods: {
      jump(url){
        if (url) {
          window.location.href = url;
        }
      },
      jumpRecruit (url) {
        window.open(url);
      },
      //跳转开发者登录
      jumpDeveloperLogin(){
          window.location.href = this.gData.developerLogin;
      },
      toHome() {
        window.location.href = `${Config.domain}/index.html`;
      }
  }
}
</script>

<style lang="scss" scoped>
.entity{
  width : 100%;
  height : 72px;
}
.headerPc{
  position: fixed;
  top : 0;
  left : 0;
  width : 100%;
  height : 72px;
  padding: 0 120px;
  box-sizing: border-box;
  background-color : rgba(255,255,255,0.5);
  z-index: 90000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  // overflow: hidden;
  // filter: blur(4px);

  &.ieStyle{
    width: 114%;

    &::before{
      display: none;
    }
  }

  .logo-content{
    transition: transform .5s ease;
    cursor: pointer;

    // &:hover{
    //   transform: translate3d(3px, -3px, 0);
    // }

    .logo{
      width : 145px;
      height : 28px;
    }
  }
  
  
  .btns{
    display : inline-block;
    vertical-align: top;
    width : auto;
    height : auto;
    // margin : 0 0 0 504px;
    color : #222222;
    font-size: 16px;
    
    .btnItem{
      position: relative;
      display: inline-block;
      width : auto;
      // height : 18px;
      // margin : 0 25px;
      cursor: pointer;
      font-weight: 400;
      margin-left: 50px;
      height: 72px;
      line-height: 72px;


      &:first-child{
        margin-left: 0;
      }

      .btnItemChildren{
        position: absolute;
        width: 100%;
        height: 84px;
        left: -15%;
        padding: 10px;
        background-color: rgba(245, 245, 246, 0.8);
        text-align: center;
        border-radius: 0 0 2px 2px;
        div{
          height: 32px;
          line-height: 32px;
          padding-bottom: 10px;
        }
      }
      .mouseHover:hover{
        color: #3292FF;
      }
    }
    // .btnItem:hover{
    //   color: #3292FF;
    // }
    .line{
      position: relative;
      top: -24px;
      width: 16px;
      height: 4px;
      background: #222222;
      border-radius: 2px;
      margin: 6px auto 0;
    }
    .chooseBtnItem{
      font-weight: bold;
    }
  }
  .devlopLogin{
    // position: absolute;
    // right: 180px;
    // top: 15px;
    // display: inline-block;
    width: 144px;
    height: 44px;
    border-radius: 12px;
    border: 2px solid #3292FF;
    font-size : 16px;
    // line-height: 30px;
    // text-align: center;
    // vertical-align: top;
    cursor: pointer;
    font-weight: 600;
    color: #3292FF;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .devlopLogin:hover{
    background: #3292FF;
    color: #FFFFFF;
  }
}
.headerPc:after{
  content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255,255,255,0.5);
    z-index: -1;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
}




// 适配其他尺寸
@media screen and  (max-width: 1200px) {
  // .entity{
  //   height: 54px;
  // }
  .headerPc{
    // height: 54px;
    padding: 0 30px;

    // .logo-content{
    //   .logo{
    //     width: 109px;
    //   }
    // }

    //   .line{
    //     width: 12px;
    //     height: 3px;
    //   }
    // }
  }
}


@media screen and  (max-width: 1000px) {
  // .headerPc{
  //   padding: 0 30px;

  //   .btns{
  //     .btnItem{
  //       margin-left: 30px;
  //     }
  //   }
  // }
  .headerPc{
    .btns{
      // font-size: 12px;
      .btnItem{
        margin-left: 25px;
      }
    }
  }
  
}
</style>